<template>
  <div class="pageGaeRecord">
    <!--主体内容-->
    <div class="gaeRecord">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          ref="searchWrapper"
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Rule_Name')">
                <el-input
                  v-model="formInline.titleStr"
                  :placeholder="$t('placeholder.Please_enter_the_rule_name')"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <el-input
                  v-model="formInline.parkNameStr"
                  :placeholder="$t('placeholder.Please_enter_the_parking_name')"
                  clearable
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchData"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF">
        <el-table
          header-cell-class-name="header-call-style"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :min-width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            header-align="center"
            :label="$t('button.operation')"
            width="120"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-button
                type="text"
                style="padding: 0 10px 0 0"
                @click="detailShow(row)"
                >{{ $t('button.edit_describe') }}</el-button
              >
              <el-button type="text" @click="detailShow2(row)">{{ $t('button.view') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页器-->
      <div class="pagerWrapper">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <edit-desc ref="editDesc" @success="searchData"></edit-desc>
  </div>
</template>

<script>
import EditDesc from "./editDesc.vue";
export default {
  name: "CalculateManageConf",
  data() {
    return {
      tableCols: [
        {
          prop: "title",
          label: this.$t("list.Rule_Name"),
          width: "160",
        },
        {
          prop: "descMsg",
          label: this.$t("list.Rule_description"),
          width: "400",
        },
        {
          prop: "parkList",
          label: this.$t("list.Binding_park"),
          width: "240",
        },
        {
          prop: "bindDate",
          label: this.$t("list.Binding_time"),
          width: "160",
        },
        {
          prop: "bindOperatorName",
          label: this.$t("list.Bind_operator"),
          width: "120",
        },
      ],
      formInline: {
        parkNameStr: "",
        titleStr: "",
      },
      tableData: [],
      pageSize: 15,
      page: 1,
      total: 0,
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.$refs["searchWrapper"].clearValidate();
      this.formInline.parkNameStr = "";
      this.formInline.titleStr = "";
    },
    // 首页列表查询
    searchData() {
      this.$axios
        .post("/acb/2.0/roadOutEngine/queryFeeList", {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            parkNameStr: this.formInline.parkNameStr,
            titleStr: this.formInline.titleStr,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.tableData = [];
            this.total = 0;
            this.$alert(res.desc);
          }
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    detailShow2(item) {
      this.$router.push(`/bindDetail?topId=${item.topId}`);
    },
    detailShow(item) {
      this.$refs.editDesc.open(item);
    },
  },
  components: { EditDesc },
  created() {
    this.searchData();
  },
  mounted() {},
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.EocConnectDialog >>>.afterChange .cell {
  color: #ff4433;
}

.dialogHeight {
  max-height: 400px;
  overflow-x: auto;
}

.displayFlex {
  display: flex;
}

.flex {
  flex: 1;
}

.pageGaeRecord {
  overflow: hidden;

  >>>.el-table {
    width: 100% !important;
  }

  .dialogRight >>>.el-table {
    border-left: none;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.picBox {
  padding-top: 50px;
  box-sizing: border-box;
  text-align: center;
}

.pciTile {
  text-align: center;
  margin-top: 15px;
}

.btn {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
</style>
<style lang="stylus">
.pageGaeRecord .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}

.merchant_name {
  margin-top: 20px;
  display: flex;

  >p {
    padding-top: 10px;
    margin-right: 20px;
  }
}
</style>
