<template>
  <el-dialog
    title="编辑描述"
    :visible.sync="modelDialog"
    custom-class="model-dialog"
    width="600px"
    @close="handleClose"
  >
    <el-form
      :model="formInline"
      :rules="rules"
      label-width="100px"
      label-position="right"
      ref="form"
    >
      <el-form-item :label="$t('searchModule.Rule_Name')" prop="title">
        <el-input v-model.trim="formInline.title" disabled></el-input>
      </el-form-item>
      <el-form-item label="规则描述" prop="descMsg"
        ><el-input
          type="textarea"
          placeholder="请输入规则描述"
          v-model="formInline.descMsg"
          :autosize="{ minRows: 2, maxRows: 16 }"
          show-word-limit
          maxlength="600"
        >
        </el-input
      ></el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitData">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      modelDialog: false,
      loading: false,
      formInline: {
        title: "",
        descMsg: "",
        topId: ""
      },
      rules: {
        title: [
          {
            required: true,
            message: "请输入规则名称",
            trigger: "blur",
          },
        ],
        descMsg: [
          {
            required: true,
            message: "请输入规则描述",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    open(data) {
      this.modelDialog = true;
      Object.keys(this.formInline).forEach(item => {
        this.formInline[item] = data[item]
      });
    },
    submitData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios
            .post("/acb/2.0/roadOutEngine/editCharge", {
              data: {
                descMsg: this.formInline.descMsg,
                topChargeId: this.formInline.topId,
              },
            })
            .then(({ value }) => {
              this.$message({
                type: "success",
                message: "编辑成功",
              });
              this.$emit("success");
            })
            .finally(() => {
              this.modelDialog = false;
              this.loading = false;
            });
        }
      });
    },
    handleClose() {
      this.$refs["form"].clearValidate();
      this.modelDialog = false;
    },
  },
};
</script>
<style lang="stylus" rel="stylesheet/stylus" scoped>
.model-dialog
  .dialog-footer
    text-align center
</style>
